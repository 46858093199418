$(function(){

    $('form#estimate').on('change', 'input,select', function(){
        var data = getFormArray($('form#estimate')[0]);
        if(data.plan){
            var obj = calcPlan(data);
            var calc = Number( obj.calc );
            var calcTax = Math.floor(calc*0.1);
            var calcTotal = calc+calcTax;
            
            var calcStr = "&yen;" + calc.toLocaleString();
            var calcTaxStr = "&yen;" + calcTax.toLocaleString();
            var calcTotalStr = "&yen;" + calcTotal.toLocaleString();

            $('form#estimate').find('[data-target=calc]').html(calcStr);
            $('form#estimate').find('[data-target=calcTax]').html(calcTaxStr);
            $('form#estimate').find('[data-target=calcTotal]').html(calcTotalStr);

            $('form#estimate').find('[name=calc]').val(calcStr);
            $('form#estimate').find('[name=calc_tax]').val(calcTaxStr);
            $('form#estimate').find('[name=calc_total]').val(calcTotalStr);

            $('form#estimate').find('[name=plan_name]').val(obj.plan_name);
            $('form#estimate').find('[name=option_names]').val(obj.option_names);
        }
    });
});

/*******************************************
 *  
*******************************************/


window.getFormArray = function(form) {
    var data = {};
    $(form).find("input, select, textarea").each(function() {
        var el = $(this);
        var name = el.attr("name");
        var value = el.val();
        if (
            typeof name === "undefined" ||
            el.attr("disabled") ||
            this.tagName.toLowerCase() === "input" && el.attr("type") === "checkbox" && !el.prop('checked') ||
            this.tagName.toLowerCase() === "input" && el.attr("type") === "radio" && !el.prop('checked')
        ){
            return;
        }
        if (name in data) {
            if (data[name] instanceof Array) {
                data[name].push(value);
            }
            else {
                data[name] = [data[name], value];
            }
        }
        else {
            data[name] = value;
        }
    });
    return data;
}