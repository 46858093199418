window._ = require('lodash');

window.Popper = require('popper.js').default;

/**
 * jQuery
 */

window.$ = window.jQuery = require('jquery');
import validate from 'jquery-validation';


/**
 * bootstrap
 */

require('bootstrap');


/**
 * Datepicker
 */

import moment from 'moment/moment'
import 'moment/locale/ja'
moment.locale('ja')
global.moment = moment
require('tempusdominus-bootstrap-4');


/**
 * object_fit_images
 */

require('./object_fit_images.js');


/**
 * project
 */

require('./common.js');
require('./calc.js');
require('./form.js');
require('./planbox.js');