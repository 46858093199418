$(function(){

'use strict';

/*******************************************
 * 
 * xxx
 * 
 *******************************************/

(function() {

    // element
    var elm = $('.PlanBox__spec');

    // var
    
    // init
    function init() {
        if(elm.length > 0){
            setup();
        }
    }

    //func
    function setup() {
        elm.each(function(){
            var body = $(this).find('.__body');
            $(this).find('.__button').on('click',function(){
                $(this).toggleClass('is-active');
                body.slideToggle();
            });
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});