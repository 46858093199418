$(function(){

'use strict';

/*******************************************
 * 
 * index
 * 
 *******************************************/

(function() {
    
    // element
    var elm_form = $('form#estimate');

    // var
    
    // init
    function init() {
        if(elm_form.length){
            //setup();
            test_setup();
        }
    }

    //func
    function setup() {
        elm_form.validate({
            rules : {
                plan : { required: true },
            },
            ignore:'',
            errorElement : "div",
            errorClass: "__error",
            messages: {
                plan : {
                    required: "プランを選択してください。"
                },
            },
            errorPlacement: function(error, element){
                // if( element.is(":radio") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.is(":checkbox") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.attr("name")=="xxx" ){
                //     error.appendTo( element.parent() );
                // }
                // This is the default behavior
                error.appendTo( elm_form.find('[data-target="plan-error"]') );
            }
        });
    }

    function test_setup(){
        $("#form_test").on('click', function () {
            $('[name=check_platform_request]').eq(1).prop('checked', true);
            // $('[name=form_type]').val('詳しく話を聞きたい');
            // $('[name=form_name]').val('名前名前名前');
            // $('[name=form_company]').val('会社名会社名');
            // $('[name=form_mail]').val('dummy@test.a.a');
            // $('[name=form_zip]').val('123-4567');
            // $('[name=form_address1]').val('住所住所住所住所住所1');
            // $('[name=form_address2]').val('住所住所住所住所住所2');
            // $('[name=form_tel]').val('0123456789');
            // $('[name=form_comment]').val('コメントコメントコメント');
        });
    }

    init();

})();


/*******************************************
 * 
 * estimate_entry
 * 
 *******************************************/

(function() {
    
    // element
    var elm_form = $('form#estimate_entry');

    // var
    
    // init
    function init() {
        if(elm_form.length){
            //setup();
            test_setup();
        }
    }

    //func
    function setup() {
        elm_form.validate({
            rules : {
                customer_company_name : { required: true },
                
            },
            ignore: ".is-disabled",
            // groups: {
            // 	xxx: "xxx xxx",
            // },
            errorElement : "div",
            errorClass: "_error",
            messages: {
                customer_company_name : {
                    required: "この項目は必須です。"
                },
                
            },
            errorPlacement: function(error, element){
                // if( element.is(":radio") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.is(":checkbox") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.attr("name")=="xxx" ){
                //     error.appendTo( element.parent() );
                // }
                // This is the default behavior
                error.appendTo( element.parents('.FormRows__item').find('.FormRows__error') );
            }
        });
    }

    function test_setup(){
        $("#form_test").on('click', function () {
            $('[name=check_platform_request]').eq(0).prop('checked', true);
            $('[name=check_platform_name]').val('希望プラットフォーム');
            $('[name=check_location]').eq(1).prop('checked', true);
            $('[name=check_lan]').eq(0).prop('checked', true);
            $('[name=check_security]').eq(1).prop('checked', true);
            $('[name=check_archive]').eq(0).prop('checked', true);
            
            $('[name=customer_company_name]').val('御社名（団体名）');
            $('[name=customer_personal_name]').val('ご担当者名');
            $('[name=customer_tel]').val('111122223333');
            $('[name=customer_email]').val('mailaddress@dummy.dummy.dummy');
            $('[name=customer_zip1]').val('111');
            $('[name=customer_zip2]').val('2222');
            $('[name=customer_address]').val('住所住所住所住所住所住所住所');
            $('[name=customer_broadcast_content]').val('配信内容');
            $('[name=customer_broadcast_date]').val('2021年 12月 31日');
            $('[name=customer_broadcast_time_start]').val('11:11');
            $('[name=customer_broadcast_time_end]').val('22:22');
            $('[name=customer_place]').val('会場会場会場会場会場');
            $('[name=customer_comment]').val('備考備考備考備考備考備考備考');
        });
    }

    init();

})();

/*******************************************
 * 
 * contact_entry
 * 
 *******************************************/

(function() {
    
    // element
    var elm_form = $('form#contact_entry');

    // var
    
    // init
    function init() {
        if(elm_form.length){
            //setup();
            test_setup();
        }
    }

    //func
    function setup() {
        elm_form.validate({
            rules : {
                customer_company_name : { required: true },
                
            },
            ignore: ".is-disabled",
            // groups: {
            // 	xxx: "xxx xxx",
            // },
            errorElement : "div",
            errorClass: "_error",
            messages: {
                customer_company_name : {
                    required: "この項目は必須です。"
                },
                
            },
            errorPlacement: function(error, element){
                // if( element.is(":radio") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.is(":checkbox") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.attr("name")=="xxx" ){
                //     error.appendTo( element.parent() );
                // }
                // This is the default behavior
                error.appendTo( element.parents('.FormRows__item').find('.FormRows__error') );
            }
        });
    }

    function test_setup(){
        $("#form_test").on('click', function () {
            $('[name=customer_company_name]').val('御社名（団体名）');
            $('[name=customer_personal_name]').val('ご担当者名');
            $('[name=customer_tel]').val('111122223333');
            $('[name=customer_email]').val('mailaddress@dummy.dummy.dummy');
            $('[name=customer_zip1]').val('111');
            $('[name=customer_zip2]').val('2222');
            $('[name=customer_address]').val('住所住所住所住所住所住所住所');
            $('[name=customer_broadcast_content]').val('配信内容');
            $('[name=customer_broadcast_date]').val('2021年 12月 31日');
            $('[name=customer_broadcast_time_start]').val('11:11');
            $('[name=customer_broadcast_time_end]').val('22:22');
            $('[name=customer_place]').val('会場会場会場会場会場');
            $('[name=customer_comment]').val('備考備考備考備考備考備考備考');
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});